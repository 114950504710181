<template>
  <div v-if="shouldShow" class="upload-progress" :class="{ 'upload-progress--minimized': isMinimized }">
    <v-layout column>
      <v-list class="pt-0">
        <v-flex class="upload-progress__header" @click="toggleMinimize">
            <v-layout row align-center justify-space-between>
            <v-flex class="upload-progress__title">
                <w-icon small class="mr-2">fas fa-upload</w-icon>
                <span>{{ displayTitle }}</span>
            </v-flex>
            <v-flex shrink class="upload-progress__actions">
                <v-btn icon @click.stop="toggleMinimize">
                <w-icon>{{ isMinimized ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}</w-icon>
                </v-btn>
            </v-flex>
            </v-layout>
        </v-flex>

        <transition name="slide">
        <v-flex v-if="!isMinimized" class="upload-progress__content">
          <!-- Show consolidated view for large uploads -->
          <template v-if="shouldShowConsolidatedView">
            <v-layout column class="upload-item">
              <v-layout row align-center class="upload-item__info">
                <w-icon small class="mr-2">fas fa-files</w-icon>
                <v-flex class="upload-item__name">
                  {{ $t('documents.upload.stats.progress', { completed: uploadStats.completed, total: uploadStats.total }) }}
                  <div class="upload-item__size">
                    {{ formattedUploadedSize }} / {{ formattedTotalSize }}
                  </div>
                </v-flex>
              </v-layout>

              <v-layout row align-center class="upload-item__progress">
                <v-flex>
                  <v-progress-linear
                    :value="globalProgression"
                    height="4"
                    rounded
                    color="primary"
                  ></v-progress-linear>
                </v-flex>
                <v-flex shrink class="upload-item__percentage ml-2">{{ globalProgression }}%</v-flex>
              </v-layout>

              <v-layout v-if="currentUpload" row align-center class="upload-item__current-file">
                <w-icon small class="mr-2">{{ getFileIcon(currentUpload) }}</w-icon>
                <span class="upload-item__current-path">
                  {{ currentUpload.path || currentUpload.name }}
                </span>
              </v-layout>
            </v-layout>
          </template>

          <!-- Show individual files for small uploads -->
          <template v-else>
            <v-layout v-for="upload in visibleUploads" :key="upload.id" column class="upload-item">
              <v-layout row align-center class="upload-item__info">
                <w-icon small class="mr-2">{{ getFileIcon(upload) }}</w-icon>
                <v-flex class="upload-item__name">{{ upload.name }}</v-flex>
                <v-flex shrink class="upload-item__size">{{ $humanReadableSize(upload.size) }}</v-flex>
              </v-layout>

              <v-layout row align-center class="upload-item__progress">
                <v-flex>
                  <v-progress-linear
                    :value="upload.progression"
                    height="4"
                    rounded
                    color="primary"
                  ></v-progress-linear>
                </v-flex>
                <v-flex shrink class="upload-item__percentage ml-2">{{ upload.progression }}%</v-flex>
              </v-layout>
            </v-layout>

            <div v-if="uploads.length > visibleUploads.length" class="upload-progress__more">
              {{ $t('documents.upload.stats.more_items', { count: uploads.length - visibleUploads.length }) }}
            </div>
          </template>
        </v-flex>
        </transition>
      </v-list>
    </v-layout>
  </div>
</template>

<script>
import { getFileIconSettings } from '@/helpers/files'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

const CONSOLIDATED_VIEW_THRESHOLD = 29 // Show consolidated view when more than 29 files

export default {
  name: 'UploadProgress',
  mixins: [AppModuleGuard],
  data() {
    return {
      uploads: [],
      uploadsCache: [],
      isMinimized: false,
      DEFAULT_FILE_ICON: 'fas fa-file',
      isVisible: false,
      displayedUploads: [], // For virtual scrolling
      uploadStats: {
        total: 0,
        completed: 0,
        inProgress: 0
      },
      useConsolidatedView: false, // Track if we should use consolidated view for this session
      currentUpload: null
    }
  },
  computed: {
    shouldShow() {
      return this.isVisible && (this.uploads.length > 0 || this.uploadsCache.length > 0)
    },
    displayTitle() {
      return this.$tc('documents.upload.progress_title', this.uploadStats.total, { count: this.uploadStats.total })
    },
    shouldShowConsolidatedView() {
      return this.useConsolidatedView
    },
    globalProgression() {
      if (!this.uploadsCache.length) {
        return 0
      }
      if (this.shouldShowConsolidatedView) {
        const completedUploads = this.uploadsCache.filter(upload => upload.progression === 100).length
        return Math.round((completedUploads / this.uploadsCache.length) * 100)
      }
      const total = this.uploadsCache.reduce((acc, upload) => acc + upload.progression, 0)
      return Math.round(total / this.uploadsCache.length)
    },
    totalSizeUploaded() {
      return this.uploadsCache.reduce(
        (acc, value) => acc + value.size * (value.progression / 100),
        0
      )
    },
    totalSizeToUpload() {
      return this.uploadsCache.reduce((acc, value) => acc + value.size, 0)
    },
    uploadsInProgress() {
      return this.uploads?.length > 0 || this.globalProgression < 100
    },
    visibleUploads() {
      const maxVisible = 50 // Show only last 50 active uploads
      return this.uploads.slice(-maxVisible)
    },
    formattedUploadedSize() {
      const size = isNaN(this.totalSizeUploaded) ? 0 : this.totalSizeUploaded
      return this.$humanReadableSize(size)
    },
    formattedTotalSize() {
      const size = isNaN(this.totalSizeToUpload) ? 0 : this.totalSizeToUpload
      return this.$humanReadableSize(size)
    }
  },
  watch: {
    uploads: {
      handler(newUploads) {
        this.updateUploadStats(newUploads)
      },
      deep: true
    }
  },
  mounted() {
    // Load minimized state from localStorage
    this.isMinimized = localStorage.getItem('uploadProgress_preference') === 'minimized'

    // Add beforeunload handler
    window.onbeforeunload = () => {
      if (this.uploadsInProgress) {
        return this.$tc('documents.upload.unload', this.uploads.length)
      }
    }
  },
  beforeDestroy() {
    // Remove beforeunload handler
    window.onbeforeunload = null
  },
  methods: {
    getAppEventsActionsMapping() {
      return [
        { event: this.appEvents.ADD_UPLOAD_PROGRESS, action: this.onAddUploadProgress },
        { event: this.appEvents.REMOVE_UPLOAD_PROGRESS, action: this.onRemoveUploadProgress }
      ]
    },
    findExistingUpload(uploadItem) {
      return this.uploads.findIndex(item =>
        item.id === uploadItem.id ||
        (item.name === uploadItem.name && item.size === uploadItem.size)
      )
    },
    findCacheIndex(uploadItem) {
      return this.uploadsCache.findIndex(item =>
        item.id === uploadItem.id ||
        (item.name === uploadItem.name && item.size === uploadItem.size)
      )
    },
    updateUploadProgress(array, index, progression) {
      this.$set(array, index, {
        ...array[index],
        progression
      })
    },
    updateExistingUpload(uploadItem, existingIndex) {
      // Update in uploads array
      this.updateUploadProgress(this.uploads, existingIndex, uploadItem.progression)

      // Update in cache if exists
      const cacheIndex = this.findCacheIndex(uploadItem)
      if (cacheIndex !== -1) {
        this.updateUploadProgress(this.uploadsCache, cacheIndex, uploadItem.progression)
      }

      // Update current upload if it's the same file
      if (this.currentUpload?.id === uploadItem.id) {
        this.currentUpload = { ...this.uploads[existingIndex] }
      }
    },
    addNewUpload(uploadItem) {
      this.uploads.push(uploadItem)
      this.uploadsCache.push(uploadItem)
      this.currentUpload = { ...uploadItem }
    },
    checkConsolidatedViewThreshold() {
      if (!this.useConsolidatedView && this.uploadsCache.length >= CONSOLIDATED_VIEW_THRESHOLD) {
        this.useConsolidatedView = true
      }
    },
    onAddUploadProgress(uploadItem) {
      this.isVisible = true
      this.checkConsolidatedViewThreshold()

      const existingIndex = this.findExistingUpload(uploadItem)
      if (existingIndex !== -1) {
        this.updateExistingUpload(uploadItem, existingIndex)
      } else {
        this.addNewUpload(uploadItem)
      }

      // Always update currentUpload to show the most recent active upload
      if (!this.currentUpload || uploadItem.progression < 100) {
        this.currentUpload = { ...uploadItem }
      }
    },
    updateCurrentUpload() {
      const inProgressUploads = this.uploads.filter(u => u.progression < 100)
      if (inProgressUploads.length > 0) {
        const lowestProgressUpload = inProgressUploads.reduce((lowest, current) =>
          current.progression < lowest.progression ? current : lowest
        , inProgressUploads[0])
        this.currentUpload = { ...lowestProgressUpload }
      } else if (this.uploads.length > 0) {
        this.currentUpload = { ...this.uploads[this.uploads.length - 1] }
      } else {
        this.currentUpload = null
      }
    },
    onRemoveUploadProgress(uploadItemId) {
      // Remove from uploads array
      const index = this.uploads.findIndex(item => item.id === uploadItemId)
      if (index !== -1) {
        this.uploads.splice(index, 1)
      }

      this.updateCurrentUpload()

      // Reset all if no more uploads
      if (this.uploads.length === 0) {
        this.uploadsCache = []
        this.useConsolidatedView = false
        this.currentUpload = null
        this.totalItemsToUpload = 0
      }
    },
    toggleMinimize() {
      this.isMinimized = !this.isMinimized
      localStorage.setItem('uploadProgress_preference', this.isMinimized ? 'minimized' : 'maximized')
    },
    close() {
      // Only close if all uploads are complete
      if (this.globalProgression === 100) {
        this.uploads = []
        this.uploadsCache = []
        this.useConsolidatedView = false // Reset consolidated view state
        this.isVisible = false
      }
    },
    getFileIcon(upload) {
      const iconSettings = getFileIconSettings(upload.type)
      return iconSettings?.icon || this.DEFAULT_FILE_ICON
    },
    updateUploadStats() {
      // For individual files, maintain total from cache and count completed from current uploads
      const total = this.uploadsCache.length
      const completedFromCache = this.uploadsCache.filter(upload => upload.progression === 100).length
      this.uploadStats = {
        total,
        completed: completedFromCache,
        inProgress: total - completedFromCache
      }
    },
    updateFolderStats(folderInfo, uploadItem) {
      if (!this.uploadsCache.some(u => u.id === uploadItem.id)) {
        folderInfo.totalFiles++
      }
      if (uploadItem.progression === 100) {
        folderInfo.completedFiles++
      }
    }
  }
}
</script>

<style scoped>
.upload-progress {
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 360px;
  background-color: var(--v-cards-base);
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;
}

.upload-progress--minimized {
  width: 240px;
}

.upload-progress__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: var(--v-primary-base);
  color: white;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  transition: background-color 0.3s ease;
}

.upload-progress__header:hover {
  background: var(--v-primary-lighten1);
}

.upload-progress__title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-progress__actions {
  display: flex;
  align-items: center;
}

.upload-progress__actions .v-btn {
  margin: 0;
  height: 28px;
  width: 28px;
}

.upload-progress__actions .v-btn i {
  font-size: 16px;
  color: white;
}

.upload-progress__content {
  max-height: 300px;
  overflow-y: auto;
  padding: 8px;
  background-color: var(--v-cards-base);
}

.upload-item {
  padding: 8px;
  border-bottom: 1px solid var(--v-divider-base);
}

.upload-item:last-child {
  border-bottom: none;
}

.upload-item__info {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-top: 12px;
}

.upload-item__name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  font-size: 13px;
  line-height: 1.2;
  padding-right: 8px;
}

.upload-item__name span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-item__size {
  font-size: 12px;
  white-space: nowrap;
  text-align: right;
}

.upload-item__progress {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.upload-item__percentage {
  font-size: 12px;
  min-width: 40px;
  text-align: right;
}

.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease, opacity 0.2s ease;
  max-height: 300px;
  opacity: 1;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
  padding: 0;
}

.upload-progress__stats-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.4;
}

.upload-progress__stats-row + .upload-progress__stats-row {
  margin-top: 4px;
}

.upload-progress__stat {
  display: flex;
  gap: 4px;
  align-items: center;
}

.upload-progress__stat--highlight {
  font-weight: 500;
}

.upload-progress__more {
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

.upload-item__current-file {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin-top: 4px;
  font-size: 12px;
  border-radius: 4px;
}

.upload-item__current-path {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-progress__size-info {
  display: none;
}
</style>
